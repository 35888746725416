<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading :active="isActive" spinner="bar-fade-scale" color="#428BCA"></vue-element-loading>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right">
              Tipo
            </label>
            <div class="col-sm-9">
              <select v-model="form.type">
                <option value="">Selecione</option>
                <option v-for="(r, k) in types" :key="k" :value="r.value">{{ r.name }}</option>
              </select>
            </div>
          </div>


          <input-form-text textInput="Pokemon" v-model="base.pokemon"
            :value="base.pokemon" v-if="form.type === 'horaDestaque' || form.type === 'horaRaid'  || form.type === 'raid' || form.type === 'comunitario'"></input-form-text>
          <input-form-text textInput="Pokedex" v-model="form.pokemon"
                      :value="form.pokemon" v-if="form.type === 'horaDestaque' || form.type === 'horaRaid' || form.type === 'raid' || form.type === 'comunitario' || form.type === 'geral'"></input-form-text>

            <div class="form-group" v-if="form.type === 'horaDestaque' || form.type === 'horaRaid' || form.type === 'raid' || form.type === 'comunitario' || form.type === 'geral'">
              <label class="col-sm-3 control-label no-padding-right">
                Shiny?
              </label>
              <div class="col-sm-9">
                <select v-model="form.shiny">
                  <option value="">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>

            <div class="form-group" v-if="form.type === 'horaDestaque' || form.type === 'horaRaid' || form.type === 'comunitario'  || form.type === 'lure'">
              <label class="col-sm-3 control-label no-padding-right" for="dia">
                Dia</label>
              <div class="col-sm-9">
                <the-mask class="col-xs-10 col-sm-3" type="text" name="dia" :mask="['##/##/####']"
                  v-model="base.dia" placeholder="Dia"  :masked="true"></the-mask>
              </div>
            </div>
            <div class="form-group" v-if="form.type === 'raid'">
                <label class="col-sm-3 control-label no-padding-right" for="dia">
                  Inicio</label>
                <div class="col-sm-9">
                  <the-mask class="col-xs-10 col-sm-3" type="text" name="inicio" :mask="['##/##/####']"
                    v-model="base.inicio" placeholder="Inicio"  :masked="true"></the-mask>
                </div>
              </div>
              <div class="form-group" v-if="form.type === 'raid'">
                <label class="col-sm-3 control-label no-padding-right" for="dia">
                  Fim</label>
                <div class="col-sm-9">
                  <the-mask class="col-xs-10 col-sm-3" type="text" name="fim" :mask="['##/##/####']"
                    v-model="base.fim" placeholder="Fim" required="" :masked="true"></the-mask>
                </div>
              </div>

          <input-form-text textInput="Título Pt" :required="true" v-model="form.title"
            :value="form.title"></input-form-text>
          <input-form-text textInput="Título En" :required="true" v-model="form.title_en"
            :value="form.title_en"></input-form-text>
          <input-form-text textInput="Título Es" :required="true" v-model="form.title_es"
            :value="form.title_es"></input-form-text>

          <div class="widget-box" v-if="form.type === 'geral' || form.type === 'lure'">
            <div class="widget-header">
              <h4 class="widget-title">Preencher somente se existir</h4>
            </div>
            <div class="widget-body">
              <div class="widget-main">
              <input-form-text textInput="Coordenadas" v-model="base.coordenada"
              :value="base.coordenada"></input-form-text>
              <input-form-text textInput="Pais" v-model="base.pais"
                :value="base.pais"></input-form-text>
              <input-form-text textInput="Bandeira" v-model="base.bandeira"
                  :value="base.bandeira"></input-form-text>
              <input-form-text textInput="Cidade" v-model="base.cidade"
                :value="base.cidade"></input-form-text>

          <div class="form-group">
              <label
                class="col-sm-3 control-label no-padding-right"
                for="observacao"
              >
                Lugares</label
              >
              <div class="col-sm-8">
                <textarea
                  class="form-control autosize"
                  v-model="form.lugares"
                  placeholder="Descrição"
                  rows="4"
                ></textarea>
              </div>
            </div>
            </div>
            </div>
            </div>
          <input-form-text textInput="Filtro de Imagem" v-model="filtro.titulo" :value="filtro.titulo"></input-form-text>
            <div class="form-group flex flex-1 filtro" v-for="f in filtros" :key="f.id" @click="setImagem(f)">
              <div class="col-sm-3 flex justify-end"><img class="img" :src="f.imagem" /></div>
              <div class="col-sm-5 flex items-center">{{ f.title }}</div>
            </div>
          <div class="form-group" v-if="!form.img">
						<label class="col-sm-3 control-label no-padding-right">Imagem 1</label>
						<div class="col-sm-5">
							<file-upload v-if="!allImages.length" ref="fupload" :url="'/eventos/upload'" :id="null"
								:label="'PNG, JPG, GIF até 10 MB'" :multiple="false" :width="450"></file-upload>
							<items-edit v-else :files="allImages"
								url="/eventos/upload" id="id"></items-edit>
						</div>
					</div>
          <div class="form-group" v-else>
            <label class="col-sm-3 control-label no-padding-right">Imagem</label>
            <div class="col-sm-5">
              <img class="img2" :src="imagem" />
              <div @click.prevent="apagarImagem" class="tool-bar flex justify-content-start mb-6 text-gray-400 space-x-3 bg-gray-200 my-2 ml-2 bg-gray-100 w-max p-2 rounded-lg" files="" temporaryfiles="">
                <i title="Apagar" class="transition-colors duration-700 cursor-pointer hover:text-red-400 fa fa-trash-o"></i>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="inicio">
              Inicio</label>
            <div class="col-sm-9">
              <the-mask class="col-xs-10 col-sm-3" type="text" name="inicio" :mask="['##/##/#### ##:##:##']"
                v-model="form.inicio" placeholder="Inicio" required="" :masked="true"></the-mask>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label no-padding-right" for="fim">
              Fim</label>
            <div class="col-sm-9">
              <the-mask class="col-xs-10 col-sm-3" type="text" name="fim" :mask="['##/##/#### ##:##:##']"
                v-model="form.fim" placeholder="Fim" required="" :masked="true"></the-mask>
            </div>

          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'
import { TheMask } from 'vue-the-mask'
import fileUpload from '@/components/form/file-upload/Index'
import ItemsEdit from '@/components/form/file-upload/ItemsEdit'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading,
    TheMask,
    fileUpload,
    ItemsEdit,
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    'base.dia' () {
      if(this.form.type === 'horaDestaque') {
        this.form.title = `Hora em destaque do ${this.base.pokemon}`
        this.form.title_es = `Hora Destacada de ${this.base.pokemon}`
        this.form.title_en = `${this.base.pokemon} Spotlight Hour`
        this.form.inicio = `${this.base.dia} 18:00:00`
        this.form.fim = `${this.base.dia} 19:00:00`
      }
      if (this.form.type === 'horaRaid') {
        this.form.title = `Hora Lendária do ${this.base.pokemon}`
        this.form.title_es = `Hora Legendaria de ${this.base.pokemon}`
        this.form.title_en = `${this.base.pokemon} Legendary Hour`
        this.form.inicio = `${this.base.dia} 18:00:00`
        this.form.fim = `${this.base.dia} 19:00:00`
      }
      if(this.form.type === 'comunitario') {
        this.form.title = `Dia Comunitário: ${this.base.pokemon}`
        this.form.title_es = `Día de la Comunidad: ${this.base.pokemon}`
        this.form.title_en = `Community Day: ${this.base.pokemon}`
        this.form.inicio = `${this.base.dia} 14:00:00`
        this.form.fim = `${this.base.dia} 17:00:00`
      }
      if (this.form.type === 'lure') {
        this.form.inicio = `${this.base.dia} 16:00:00`
        this.form.fim = `${this.base.dia} 16:30:00`
      }
    },
    'base.fim' () {
      if (this.form.type === 'raid') {
        this.form.title = `Reide de ${this.base.pokemon}`
        this.form.title_es = `Incursiones de ${this.base.pokemon}`
        this.form.title_en = `${this.base.pokemon} Raid`
        this.form.inicio = `${this.base.inicio} 10:00:00`
        this.form.fim = `${this.base.fim} 20:00:00`
      }

    },
    'base.coordenada'() {
      this.getCoords()
    },
    color () {
      this.changeCavas()
    },
    'base.pais'() {
      this.getCoordsOpen('pais')
    },
    'base.bandeira'() {
      this.getCoordsOpen('bandeira')
    },
    'base.cidade'() {
      this.getCoordsOpen('cidade')
    },
    'filtro.titulo' () {
      this.getFiltro()
    }
  },
  data() {
    return {
      color: '',
      ctx: null,
      title: 'Eventos',
      base: {
        pokemon: '',
        dia: '',
        inicio: '',
        fim: '',
        coordenada: '',
        pais: '',
        bandeira: '',
        cidade: '',
      },
      form: {
        title: '',
        title_es: '',
        title_en: '',
        type: '',
        inicio: '',
        fim: '',
        lugares: '',
        pokemon: '',
        shiny: '',
        img: '',
        ativo: 2
      },
      isActive: false,
      type: ['comunitario', 'raid', 'horaRaid', 'horaDestaque', 'liga', 'geral', 'lure'],
      types: [
        {
          name: 'Dia Comunitário',
          value: 'comunitario'
        },
        {
          name: 'Reide e Mega Reides',
          value: 'raid'
        },
        {
          name: 'Hora de Reides',
          value: 'horaRaid'
        },
        {
          name: 'Hora do Holofote',
          value: 'horaDestaque'
        },
        {
          name: 'Liga',
          value: 'liga'
        },
        {
          name: 'Geral',
          value: 'geral'
        },
        {
          name: 'Festa de Lures',
          value: 'lure'
        }
      ],
      allImages: [],
      filtro: {
        titulo: ''
      },
      filtros: [],
      imagem: ''
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async getFiltro() {
      const dados = await Services.get('/eventos/all', {
          offset: 5,
          'filter[logic]': 'and',
          'filter[filters][0][field]': 'titulo',
          'filter[filters][0][operator]': 'contains',
          'filter[filters][0][value]': this.filtro.titulo
      })
      console.log(dados)
      this.filtros = dados.data
    },
    async getCoords() {
      const dados = await Services.get(`/lugares/coordinates?coords=${this.base.coordenada}`)
      this.form.lugares = JSON.stringify(dados)
    },
    getCoordsOpen(type) {
       const dados = JSON.parse(this.form.lugares)
      switch (type) {
        case 'pais':
          dados.pais = this.base.pais
          this.form.lugares = JSON.stringify(dados)
          return
      case 'bandeira':
          dados.img = `/assets/flags/${this.base.bandeira}.png`
          this.form.lugares = JSON.stringify(dados)
          return
        case 'cidade':
          dados.city = this.base.cidade
          this.form.lugares = JSON.stringify(dados)
          return
      }
    },
    apagarImagem() {
      this.imagem = ''
      this.form.img = ''
      this.filtro.titulo = ''
      this.filtros = []
    },
    setImagem(img) {
      this.imagem = img.imagem
      this.form.img = img.img
      this.filtros = []
    },
    async gravar() {
      this.isActive = true
      const body = this.form
      if (this.$route.params.id) {
        const resp = await Services.put(`/eventos/${this.$route.params.id}`, body).catch(response => {
          console.log('error: ' + response)
          erro()
        })
        if (!this.allImages.length && !this.form.img) {
          this.isActive = false
          const tb = this.$refs.fupload.$children.filter(i => i.$refs.tb)
          const m = tb[0]
          await m.uploadAll(resp)
        }
        sucesso('Atualizado...')
        this.$router.push('/sistema/eventos')
      } else {
        const resp = await Services.post('/eventos', body).catch(response => {
          console.log('error: ' + response)
          erro()
        })
        if (!this.allImages.length && !this.form.img) {
          this.isActive = false
          const tb = this.$refs.fupload.$children.filter(i => i.$refs.tb)
          const m = tb[0]
          await m.uploadAll(resp)
        }
        sucesso('Atualizado...')
        this.$router.push('/sistema/eventos')
      }
      this.isActive = false
    },
    async getDados() {
      const dados = await Services.get(
        `/eventos/${this.$route.params.id}`
      ).catch(response => {
        console.log('error: ' + response)
        return (this.isActive = false)
      })
      this.form.descricao = dados.descricao
      this.form.title = dados.title
      this.form.title_es = dados.title_es
      this.form.title_en = dados.title_en
      this.form.type = dados.type
      this.form.pokemon = dados.pokemon
      this.form.shiny = dados.shiny
      this.form.img = dados.img
      this.imagem = dados.imagem
      this.allImages = dados.img ? [{ id: this.$route.params.id, images: dados.imagem }] : ''
      this.form.inicio = moment(new Date(dados.inicio)).format(
        'DD/MM/YYYY HH:mm:ss'
      )
      this.form.fim = moment(new Date(dados.fim)).format('DD/MM/YYYY HH:mm:ss')
      console.log(this.form, dados)
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped>
.img {
    width: 70px;
    object-fit: cover;
}
.img2 {
  height: 100px;
  object-fit: cover;
}
</style>
