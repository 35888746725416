<template>
    <div class="overflow-hidden sm:rounded-md h-auto">

        <!--      Tool Bar      -->
        <tool-bar :files="files" :temporaryFiles="temporaryFiles"></tool-bar>

        <!--      items     -->
        <items :files="files" :items="temporaryFiles"></items>

        <!--        Upload and drag & drop area            -->
        <vue-element-loading :active='isActive' spinner='bar-fade-scale' color='#428BCA'></vue-element-loading>
        <div>

            <div @drop.prevent="drop($event)" @click.self="clickOnInput()" @dragover.prevent="dragover($event)"
                v-if="!multiple && files.length < 1 || multiple"
                class="cursor-pointer hover:bg-gray-50 mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div @click.self="clickOnInput()" class="space-y-1 text-center">
                    <svg class="  mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                        aria-hidden="true">
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                        <label for="file-upload"
                            class="relative cursor-pointer bg-white rounded-md font-medium text-blue-500  hover:text-blue-600 transition-color duration-300">
                            <span>{{ texto }}</span>
                            <input :multiple="multiple" @change="clickedInput($event)" ref="file-upload" name="file-upload"
                                type="file" class="sr-only" :disabled="!multiple && files.length > 0" :accept="accept" />
                        </label>
                        <p class="pl-1">ou arraste e solte</p>
                    </div>
                    <p class="text-xs text-gray-500">
                        {{ label }}
                    </p>
                </div>
            </div>
        </div>
        <!--       End  Upload drag & drop area            -->


    </div>
</template>

<script>
import axios from 'axios'
import Compressor from 'compressorjs'
import VueElementLoading from 'vue-element-loading'
import ToolBar from './ToolBar'
import Items from './Items'

export default {
    name: 'FileUpload',
    props: {
        url: {
            type: String,
            default: '/',
        },
        texto: {
            type: String,
            default: 'Enviar Fotos',
        },
        accept: {
            type: String,
            default: '.jpg,.jpeg,.png,.gif',
        },
        id: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            default: 'PNG, JPG, GIF up to 10MB'
        },
        multiple: {
            type: Boolean,
            default: true
        },
        width: {
            default: null,
        },
        isFile: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
            temporaryFiles: [],
            request: new FormData(),
            files: [],
            uploading: false,
            isActive: false,
        }

    },
    components: {
        ToolBar,
        Items,
        VueElementLoading
    },
    methods: {
        dragover(e) {
            e.stopPropagation()
            e.dataTransfer.dropEffect = 'copy'
        },
        drop(e) {
            e.stopPropagation()
            this.createFile(e)
        },


        createCancelToken() {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            return source
        },
        clickOnInput() {
            const uploadInput = this.$refs['file-upload']
            uploadInput.click()
        },
        clickedInput(e) {
            this.createFile(e)
        },
        formatSize(size) {
            console.log(size)
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        },
        createUrl(cur) {
            const vm = this
            new Compressor(cur, {
                quality: 0.8,
                width: this.width,

                success(result) {
                    var reader = new FileReader()
                    reader.readAsDataURL(result)
                    reader.onloadend = async () => {
                        console.log(vm.formatSize(cur.size))
                        var base64data = reader.result
                        const curn = await fetch(base64data)
                        const blob = await curn.blob()
                        var file = new File([blob], cur.name)
                        vm.files.push(file)
                        console.log(vm.formatSize(blob.size))
                        if (cur.type.match(/video.*/)) {
                            vm.temporaryFiles.push({ type: 'video', url: URL.createObjectURL(cur), status: 'pending', percent: 0, name: cur.name })
                            vm.isActive = false
                        } else if (cur.type.match(/image.*/)) {
                            vm.temporaryFiles.push({ type: 'image', url: URL.createObjectURL(blob), status: 'pending', percent: 0, name: cur.name })
                            vm.isActive = false
                        } else {
                            vm.temporaryFiles.push({ type: 'file', url: URL.createObjectURL(cur), status: 'pending', percent: 0, name: cur.name })
                            vm.isActive = false
                        }
                    }
                    reader.onerror = error => {
                        this.isActive = false
                        alert(error)
                    }
                    this.isActive = false

                },
                error(err) {
                    this.isActive = false
                    console.log(err.message)
                },
            })


        },
        createUrlFile(cur) {
            this.isActive = false
            this.temporaryFiles.push({ type: 'file', url: URL.createObjectURL(cur), status: 'pending', percent: 0 })
        },
        createFile(e) {
            this.isActive = true
            var files = [...e.target.files || e.dataTransfer.files] // Array of all files
            if(this.isFile) {
                this.files.push(...files)
            }
            files.forEach((cur, index) => {
                if (!this.isFile) {
                    this.createUrl(cur, index)
                } else {
                    this.createUrlFile(cur, index)
                }
            })

        },
        isAllUploading() {
            let uploading = false
            let uploadingList = []

            this.temporaryFiles.forEach(cur => uploadingList.push(cur.status))

            if (uploadingList.includes('uploading')) return true

            return uploading

        }

    },
    mounted() {
        if (this.id) this.request.append('id', this.id)

    }
}
</script>

<style scoped>
.upload-images {
    width: 100px !important;
    height: 100px !important;
}

.tool-bar-img {
    font-size: 12px;
}
</style>