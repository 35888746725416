<template>
    <div id="image-container" >
        <vue-element-loading :active="isActive" spinner="bar-fade-scale"
						color="#428BCA"></vue-element-loading>
        <div v-if="isDrag">
            <a class="btn btn-primary" href="#"  @click.prevent="getComponentData">Gravar Ordenação</a>
            <draggable :list="files" group="people" @start="drag=true" @end="drag=false" class="mb-8 mx-auto flex flex-wrap justify-content-start-ml-3 h-auto">
                <div v-for="(item, index) in files" style=" width: 100px; height: 100px;"
                    class="overflow-hidden  relative image-box mt-3 ml-3 block border-2 shadow-xl rounded-md drag" :key="index">

                    <!--      preview images     -->
                    <template>
                        <div class="absolute tool-bar-img flex justify-content-start mb-6 text-gray-400 space-x-3 bg-gray-200 my-2 ml-2 bg-gray-100 w-max p-2 rounded-lg "
                            :class="(item.status == 'uploaded') && 'hidden'">
                            <i 
                                @click="destroy(item[id], item.url, index)" title="Apagar"
                                class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fa fa-trash"></i>

                        </div>
                        <div class="number absolute"
                            :class="(item.status == 'uploaded') && 'hidden'">
                            {{ index + 1 }}

                        </div>
                        <div v-if="!isFile">
                            <div v-if="capa" class="capa" @click.prevent="getSelect(item)">
                                <img style="height: 100px;" width="100px" height="100px" class="upload-images object-cover"
                                    :src="item.images" @error="placeholder">
                                <div :class="{ select : item.capa }">Capa</div>
                            </div>
                            <img v-else style="height: 100px;" width="100px" height="100px" class="upload-images object-cover"
                                    :src="item.images" @error="placeholder">
                        </div>
                        <div v-else>
                            <img style="height: 100px;" width="100px" height="100px" class="upload-images object-cover"
                                    src="./art/file.png">
                        </div>

                    </template>

                </div>
            </draggable>
        </div>
        <div class="mb-8 mx-auto flex flex-wrap justify-content-start-ml-3 h-auto" v-else>
            <div v-for="(item, index) in files" style=" width: 100px; height: 100px;"
                class="overflow-hidden  relative image-box mt-3 ml-3 block border-2 shadow-xl rounded-md" :key="index" >

                <!--      preview images     -->
                <template>
                    <div class="absolute tool-bar-img flex justify-content-start mb-6 text-gray-400 space-x-3 bg-gray-200 my-2 ml-2 bg-gray-100 w-max p-2 rounded-lg "
                        :class="(item.status == 'uploaded') && 'hidden'">
                        <i 
                            @click="destroy(item[id], item.url, index)" title="Apagar"
                            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fa fa-trash"></i>

                    </div>
                    <div v-if="!isFile">
                        <div v-if="capa" class="capa" @click.prevent="getSelect(item)">
                            <img style="height: 100px;" width="100px" height="100px" class="upload-images object-cover"
                                :src="item.images" @error="placeholder">
                            <div :class="{ select : item.capa }">Capa</div>
                        </div>
                        <img v-else style="height: 100px;" width="100px" height="100px" class="upload-images object-cover"
                                :src="item.images" @error="placeholder">
                    </div>
                    <div v-else>
                        <img style="height: 100px;" width="100px" height="100px" class="upload-images object-cover"
                                src="./art/file.png">
                    </div>

                </template>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading'
import draggable from 'vuedraggable'

export default {
    //props: ['url', 'files', 'capa', 'id', 'isFile'],
    props: {
        url: {
            type: String,
            default: '/',
        },
        files: {},
        capa: {},
        id: {},
        isFile: {
            type: Boolean,
            default: false
        },
        isDrag: {
            type: Boolean,
            default: false
        },
        urlSort: {
            type: String,
            default: '/',
        },
        idSort: {}
    },
    components: {
        VueElementLoading,
        draggable
    },
    data: () => ({
        isActive: false
    }),
    methods: {
        async getComponentData() {
            this.isActive = true
            await Promise.all(this.files.map(async (ex, ek) => {
                await axios.put(`${this.urlSort}${ex[this.idSort]}`, {
                    order: ek
                }).catch(function (error) {
                    this.isActive = false
                    console.log(error)
                })
                return ex
            }))
            this.isActive = false
        },
        async destroy(id, file, index) {
            this.isActive = true
            
            await axios.delete(`${this.url}/${id}`)
                    .catch(function (error) {
                        console.log(error)
                    })
            URL.revokeObjectURL(file)
            this.files.splice(index, 1)
            this.isActive = false
        },

        cancelUpload(index) {

            this.items[index].source.cancel()
            this.items[index].status = 'cancel'
            if (!this.$parent.isAllUploading()) this.$parent.$data.uploading = false
        },
        isAllNullExceptOne() {

            let result = this.files.reduce((total, cur) => Number(Boolean(cur)) + total)
            return result == 1
        },
        async getSelect(item) {
            await this.files.map(i => {
                i.capa = null
                return i
            })
            await axios.put(`${this.url}/${item[this.id]}`, item).catch(function (error) {
                console.log(error)
            })
            item.capa = 1
                    
        },
        placeholder (e) {
            return e.target.src = require('./art/file.png')
        }

    }
}
</script>

<style lang="scss" scoped>
.capa {
    cursor: pointer;
}
.select {
    position: absolute;
    bottom: 5px;
    width: 100%;
    background: #438eb9;
    display: flex;
    justify-content: center;
    color: #fff;
}

.drag {
    border: 1px dashed #ccc;
    padding: 5px;
}

.number {
    background-color: #438eb9;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    right: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>